var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel elementPanel--spaced fill-height" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Productie werkvoorbereiding")
            ])
          ]),
          _vm.isWerkvoorbereidingAdmin
            ? [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "", "justify-space-between": "" } },
                      [
                        _c(
                          "v-flex",
                          { staticClass: "info-icon", attrs: { shrink: "" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openReadMoreDialog(
                                                      "Dashboard_werkvoorbeiding_info"
                                                    )
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v("info_outline")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  373741188
                                )
                              },
                              [_c("span", [_vm._v("klik voor meer info")])]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "elementPanel elementPanel--spaced fill-height"
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c("PeriodFilter", {
                                attrs: {
                                  to: _vm.ends_at,
                                  from: _vm.starts_at,
                                  clearable: false
                                },
                                on: { change: _vm.applyDateFilters }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "custom-filter custom-filter--autocomplete",
                              attrs: { shrink: "" }
                            },
                            [
                              _c("UserFilter", {
                                on: { handleChange: _vm.fetchAddresses },
                                model: {
                                  value: _vm.tableFilters.users,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tableFilters, "users", $$v)
                                  },
                                  expression: "tableFilters.users"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "custom-filter custom-filter--autocomplete",
                              attrs: { shrink: "" }
                            },
                            [
                              _c("ReportSkillFilter", {
                                attrs: { canSelectAll: true },
                                on: { handleChange: _vm.fetchAddresses },
                                model: {
                                  value: _vm.tableFilters.skills,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tableFilters, "skills", $$v)
                                  },
                                  expression: "tableFilters.skills"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "custom-filter custom-filter--autocomplete",
                              attrs: { shrink: "" }
                            },
                            [
                              _c("ReportTypeFilter", {
                                attrs: { canSelectAll: true },
                                on: { handleChange: _vm.fetchAddresses },
                                model: {
                                  value: _vm.tableFilters.types,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tableFilters, "types", $$v)
                                  },
                                  expression: "tableFilters.types"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "custom-filter custom-filter--autocomplete",
                              attrs: { shrink: "" }
                            },
                            [
                              _c("ReportTagFilter", {
                                attrs: { canSelectAll: true },
                                on: { handleChange: _vm.fetchAddresses },
                                model: {
                                  value: _vm.tableFilters.tags,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tableFilters, "tags", $$v)
                                  },
                                  expression: "tableFilters.tags"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-checkbox", {
                        attrs: {
                          label: "Verberg alle inactieve gebruikers",
                          "hide-details": "",
                          name: "hideInactive",
                          color: "#a09b1b"
                        },
                        on: { change: _vm.fetchAddresses },
                        model: {
                          value: _vm.tableFilters.is_active,
                          callback: function($$v) {
                            _vm.$set(_vm.tableFilters, "is_active", $$v)
                          },
                          expression: "tableFilters.is_active"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm.isLoading
                  ? _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("LoaderCard", {
                          attrs: { flat: "", type: "spinner--center" }
                        })
                      ],
                      1
                    )
                  : _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-data-table", {
                          attrs: {
                            "hide-actions": true,
                            items: _vm.items,
                            headers: _vm.headers
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "headers",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "tr",
                                      _vm._l(props.headers, function(header) {
                                        return _c(
                                          "th",
                                          {
                                            key: header.text,
                                            on: {
                                              click: function($event) {
                                                return _vm.changeOrApplySorting(
                                                  header
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(header.text))
                                            ]),
                                            _c(
                                              "v-icon",
                                              {
                                                staticStyle: { height: "12px" },
                                                style: header.sortBigToSmall
                                                  ? "transform: rotate(180deg)"
                                                  : ""
                                              },
                                              [_vm._v(" keyboard_arrow_down ")]
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                }
                              },
                              {
                                key: "items",
                                fn: function(props) {
                                  return [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.user))
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.regulier))
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(props.item.aannemersvariant)
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.woco))
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.specials))
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.total))
                                      ])
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1927616524
                          )
                        })
                      ],
                      1
                    )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }