import { Component } from 'vue-property-decorator';
import PeriodFilter from '@/components/filters/period-filter/PeriodFilter.vue';
import { DateTime } from 'luxon';
import { Rpc } from '@/models/Rpc';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import { DashboardWerkvoorbereidingInfo } from '@/support/Info';
import { userRoles } from '@/models/User';

@Component<Dashboard>({
  components: {
    PeriodFilter,
  },
})
export default class Dashboard extends AbstractMoreInfoDialog {
  protected moreInfo: MoreInfo[] = DashboardWerkvoorbereidingInfo;

  public $pageTitle = 'Dashboard';

  protected isLoading = true;

  protected starts_at = DateTime.local().toFormat('yyyy-LL-dd');

  protected ends_at = DateTime.local()
    .plus({ days: 1 })
    .toFormat('yyyy-LL-dd');

  protected lastDateChanged = 'stats_at';

  protected tableFilters: WerkvoorbereidingTableFilters = {};

  protected userRoles = userRoles;

  protected items: WerkvoorbereidingDataTableItem[] = [];

  protected headers: WerkvoorbereidingTableHeader[] = [
    {
      text: 'Naam',
      sortBigToSmall: true,
    },
    {
      text: 'Regulier',
      sortBigToSmall: true,
    },
    {
      text: 'Aannemersvariant',
      sortBigToSmall: true,
    },
    {
      text: 'WoCo',
      sortBigToSmall: true,
    },
    {
      text: 'Specials',
      sortBigToSmall: true,
    },
    {
      text: 'Totaal',
      sortBigToSmall: true,
    },
  ];

  protected mounted() {
    this.initBreadcrumb();
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Dashboard' }],
    });
  }

  protected applyDateFilters(from: string, to: string) {
    if (this.starts_at !== from) {
      this.starts_at = from;
      this.lastDateChanged = 'starts_at';
    }
    if (this.ends_at !== to) {
      this.ends_at = to;
      this.lastDateChanged = 'ends_at';
    }
    this.fetchAddresses();
  }

  protected changeOrApplySorting(header: WerkvoorbereidingTableHeader) {
    if (! this.items.length) {
      return;
    }

    const excludeTotal = this.items.pop();

    this.items.sort((a, b) => {
      switch (header.text) {
        case 'Naam':
          if (a.user < b.user) return header.sortBigToSmall ? 1 : - 1;
          if (a.user > b.user) return header.sortBigToSmall ? - 1 : 1;
          return 0;
        case 'Regulier':
          return header.sortBigToSmall ? b.regulier - a.regulier : a.regulier - b.regulier;
        case 'Aannemersvariant':
          return header.sortBigToSmall ? b.aannemersvariant - a.aannemersvariant : a.aannemersvariant - b.aannemersvariant;
        case 'WoCo':
          return header.sortBigToSmall ? b.woco - a.woco : a.woco - b.woco;
        case 'Specials':
          return header.sortBigToSmall ? b.specials - a.specials : a.specials - b.specials;
        default:
          return header.sortBigToSmall ? b.total - a.total : a.total - b.total;
      }
    });

    header.sortBigToSmall = ! header.sortBigToSmall;

    if (excludeTotal) {
      this.items.push(excludeTotal);
    }
  }

  protected async fetchAddresses() {
    this.isLoading = true;

    if (this.starts_at > this.ends_at) {
      if (this.lastDateChanged === 'starts_at') {
        this.ends_at = this.starts_at;
      } else {
        this.starts_at = this.ends_at;
      }
    }
    if (! this.tableFilters.skills?.length) {
      delete this.tableFilters.skills;
    }
    if (! this.tableFilters.tags?.length) {
      delete this.tableFilters.tags;
    }
    if (! this.tableFilters.types?.length) {
      delete this.tableFilters.types;
    }
    if (! this.tableFilters.users?.length) {
      delete this.tableFilters.users;
    }

    const response = await new Rpc().rpcPost(
      { signature: 'planning:production-statistics-werkvoorbereiding', body: { filters: this.tableFilters, starts_at: this.starts_at, ends_at: this.ends_at } },
      false,
    );
    if (response) {
      this.items = response.data;

      if (this.items.length > 1) {
        const totalItem: WerkvoorbereidingDataTableItem = {
          user: 'Totaal',
          aannemersvariant: 0,
          regulier: 0,
          specials: 0,
          total: 0,
          woco: 0,
        };
        this.items.forEach((item: WerkvoorbereidingDataTableItem) => {
          totalItem.aannemersvariant += item.aannemersvariant;
          totalItem.regulier += item.regulier;
          totalItem.specials += item.specials;
          totalItem.total += item.total;
          totalItem.woco += item.woco;
        });
        this.items.push(totalItem);
      }
    }
    this.isLoading = false;
  }

  protected get isWerkvoorbereidingAdmin(): boolean {
    return this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles);
  }
}

interface WerkvoorbereidingTableHeader{
  text: string;
  sortBigToSmall: boolean;
}

interface WerkvoorbereidingTableFilters {
  is_active?: boolean;
  skills?: string[];
  tags?: string[];
  types?: string[];
  users?: string[];
}

interface WerkvoorbereidingDataTableItem {
  aannemersvariant: number;
  regulier: number;
  specials: number;
  total: number;
  woco: number;
  user: string;
}
